import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: [],
    coin: "",
    promotion: null,
    getOrderCart: [],
  },
  reducers: {
    getCartItems: (state, action) => {
      state.cart = action.payload;
    },
    SetGetOrderCart: (state, action) => {
      state.getOrderCart = action.payload;
    },
    getCoinRate: (state, action) => {
      state.coin = action.payload;
    },
    setPromotion: (state, action) => {
      state.promotion = action.payload;
    },
    insertBackCartItem: (state, action) => {
      const { item, index } = action.payload;
      state.cart.items.splice(index, 0, item);
    },
    removeCartItems: (state, action) => {
      const packageId = action.payload;
      state.cart.items = state.cart.items.filter(
        (item) => item.itemId._id !== packageId
      );
    },
    incrementCartItem: (state, action) => {
      const itemId = action.payload;

      for (let i = 0; i < state.cart.items.length; i++) {
        const item = state.cart.items[i];

        if (itemId === item.itemId._id) {
          state.cart.items[i].quantity += 1;
          return;
        }
      }
    },
    decrementCartItem: (state, action) => {
      const itemId = action.payload;

      for (let i = 0; i < state.cart.items.length; i++) {
        const item = state.cart.items[i];

        if (itemId === item.itemId._id) {
          state.cart.items[i].quantity -= 1;
          return;
        }
      }
    },
  },
});

export const {
  getCartItems,
  removeCartItems,
  incrementCartItem,
  decrementCartItem,
  insertBackCartItem,
  getCoinRate,
  setPromotion,
  SetGetOrderCart,
} = cartSlice.actions;
export default cartSlice.reducer;
