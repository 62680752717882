// const localLink = "http://localhost:3000";
// export const baseUri = "http://192.168.0.178:8000";
// export const baseUri = "http://lionsdeneng-env.eba-dus3uewu.us-east-2.elasticbeanstalk.com";
// staging fronted
export const stagingFrontend =
  "http://jkdigitals-staging-env.eba-dus3uewu.us-east-2.elasticbeanstalk.com";

// live frontnend
export const liveFrontend = "https://www.jkdigitals.com";
export const localFrontend = "http://localhost:3000";

// Staging server backend
// const MerchantIdKey = "lionsden";
// const coinflowEnvKey = "sandbox";
//  export const baseUri =
//    "http://lionsdengames-staging-env.eba-dus3uewu.us-east-2.elasticbeanstalk.com";
// export const linkFrontend = stagingFrontend;
// // export const linkFrontend = localFrontend
// // export const baseUri = "https://09fd-144-48-130-73.ngrok-free.app";

// export const stripePublicKey =
//   "pk_test_51Q6E0YRqNXUastCknloacnhPRVsFOXy3jQvFokuWyzxsY8g81QOgEe3ycz8Z7Nj7cMiyvfcAgabCScimsE6GfERf00pUI6ghaR";

// Production server backend
export const baseUri = "https://www.lionsprideengineering.com";
export const linkFrontend = liveFrontend;
export const stripePublicKey =
  "pk_live_51Q6E0YRqNXUastCkegHO64NZqcTlCnJUNouVhq33CQRhHhdrp97nNwQK4Xb6vNbrQAm8NTQt1mmtEtl7yEPPI57O00vB7TL88y";
const MerchantIdKey = "lionsdengames";
const coinflowEnvKey = "prod";

export const baseUrl = `${baseUri}/api/`;
export const apiPath = baseUrl;
export const merchantId = MerchantIdKey;
export const coinflowEnv = coinflowEnvKey;
