import { createSlice } from "@reduxjs/toolkit";
import storage from "../../services/storage";
const jumio = storage.get("jumio") || {};

const initialState = {
  user: {},
  jumio,
  l: "sa",
  state: {},
};

const eidtUserSlice = createSlice({
  name: "editUser",
  initialState,
  reducers: {
    setEditUser: (state, action) => {
      state.user = action.payload;
    },

    setUserJumio: (state, action) => {
      state.jumio = action.payload;
      state.jumio = jumio;
      storage.store("jumio", jumio);
    },
    setUserState: (state, action) => {
      state.state = action.payload;
    },
  },
});

export const { setEditUser, setUserState, setUpdatedEditUser, setUserJumio } =
  eidtUserSlice.actions;
export default eidtUserSlice.reducer;
