import { createSlice } from "@reduxjs/toolkit";
import storage from "../../services/storage";

const initialState = {
  custonmerInfo: {},
};

const approvelyInfo = createSlice({
  name: "approvelyInfo",
  initialState,
  reducers: {
    setCustonmerInfo: (state, action) => {
      const { data } = action.payload;
      const custonmerInfo = {
        firstName: data?.firstname,
        lastName: data?.lastname,
        displayName: data?.firstname,
        address: data?.address?.street,
        city: data?.address?.city,
        state: data?.address?.state,
        zip: data?.address?.zipCode,
        country: data?.address?.country,
        ip: data?.userip,
        // lat: lat,
        // lng: lng,
      };
      // const [lat, lng] = data?.states?.loc.split(",").map(Number);
      state.custonmerInfo = custonmerInfo;
    },
  },
});

export const { setCustonmerInfo } = approvelyInfo.actions;
export default approvelyInfo.reducer;
