import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import authReducer from "../store/auth/authState";
import authUser from "../store/auth/user";
import loginUi from "../store/ui/loginUi";
import userData from "../store/ui/userData";
import packageName from "./ui/productPackages";
import eidtUserSlice from "../store/auth/eidtProfile";
import approvelyInfo from "../store/auth/approvelyInfo";
import productsListing from "./auth/productsListing";
import cartSlice from "./ui/cart";
import loadingSlice from "./ui/loading";
import userOrderDetails from "./auth/orderDetails";
import userSavedCardsDetails from "./auth/getUsersCards";
import getSuccessData from "./ui/getSuccess";
import getAllMyPurchases from "./ui/getMyPurchases.slice";
import digitalProductAPI from "./api/digitalProduct";
const combineReducer = combineReducers({
  auth: authReducer,
  user: authUser,
  login: loginUi,
  userData: userData,
  packageName: packageName,
  approvelyInfo,
  editUser: eidtUserSlice,
  productListingResp: productsListing,
  cart: cartSlice,
  loading: loadingSlice,
  orderDetails: userOrderDetails,
  usersCards: userSavedCardsDetails,
  getSuccessData: getSuccessData,
  myPurchases: getAllMyPurchases,
  digitalProductAPI: digitalProductAPI,
});

const store = configureStore({
  reducer: combineReducer,
});

export default store;
