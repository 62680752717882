import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./index.css";
import { Provider } from "react-redux";
import store from "./store/Store";

import "./assets/style3.css";
import "./assets/responsive2.css";
import "./assets/style.css";
import "./assets/satalite.style.css";
import "./assets/satalite.responsive.css";
import "./assets/HomeAssets/assets/css/style.css";
import "./assets/HomeAssets/assets/css/responsive.css";
import "./assets/HomeAssets/assets/css/aos.css";
// import "./assets/HomeAssets/assets/css/owl.carousel.min.css"
import "./assets/style2.css";
import "./assets/responsive.css";
import "./assets/AddAssets/assets/css/style.css";
import "./assets/AddAssets/assets/css/responsive.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

// import Home from "./pages/Home/Home";
import ProtectedRoutes from "./router/Protected";
import Redirect from "./router/Redirect";
// import ScrollTop from "./components/ScrollTop";
// import HomePage from "./pages/homepage/HomePage";
// import AboutUs from "../src/pages/homepage/AboutUs/AboutUs";
// import CreateUser from "../src/Components/CreateUser/CreateUser";
// import SuccessScreeen from "./pages/PurchasingSuccess/SuccessScreeen";
// import SingleProductDetails from "./pages/SingleProductDetail/SingleProductDetails";
// import LoginPage from "../src/pages/Login/LoginPage";
// import Cart from "../src/pages/Cart/Cart";
// import ForgotPassword from "./pages/ForgotPassword";
// import ResendEmail from "./pages/ResendEmail";
// import NewPassword from "./pages/NewPassword";
// import ContinuePage from "./pages/ContinuePage";
// import AddCard from "./pages/AddCard/AddCard";
// import ScrollTop from "./Components/ScrollTop";
// import MyPurchase from "./pages/MyPurchase/MyPurchase";
// import AllProducts from "../src/pages/AllProducts/AllProducts";
// import SocketConnection from "./Components/SocketConnection";
// import Jumio from "./Components/Jumio";
// import VerifyIndentity from "./pages/VerifyIdentity/VerifyIndentity";
// import GiftCards from "./pages/GiftCards/GiftCards";
// import Contact from "./pages/Contact/Contact";
// import SuccessScreenDigitalProduct from "./pages/PurchasingSuccess/SuccessScreenGiftCard";
// import PrivacyPolicy from "./pages/Policy/PrivacyPolicy";
// import TermsofUse from "./pages/Policy/TermOfUse";
// import DataDeletion from "./pages/Policy/DataDeletion";
// import  KycInfo  from "./pages/kycInfo/KycInfo";
import { Suspense, lazy, useEffect, useState } from "react";
import Loader from "./Components/Layout/Loader/Loader";
import LogRocket from "logrocket";
import { CoinflowPurchaseProtection } from "@coinflowlabs/react";
import { coinflowEnv, merchantId } from "./config.js";

// const lazyRetry = function (componentImport) {
//   return new Promise((resolve, reject) => {
//     // check if the window has already been refreshed
//     const hasRefreshed = JSON.parse(
//       window?.sessionStorage?.getItem("retry-lazy-refreshed") || "false"
//     );
//     // try to import the component
//     componentImport()
//       .then((component) => {
//         window?.sessionStorage?.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
//         resolve(component);
//       })
//       .catch((error) => {
//         if (!hasRefreshed) {
//           // not been refreshed yet
//           window?.sessionStorage?.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
//           return window?.location?.reload(); // refresh the page
//         }
//         reject(error); // Default error behaviour as already tried refresh
//       });
//   });
// };

//

const lazyRetry = (componentImport) => {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window?.sessionStorage?.getItem("retry-lazy-refreshed") || "false"
    );

    componentImport()
      .then((component) => {
        window?.sessionStorage?.setItem("retry-lazy-refreshed", "false");
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window?.sessionStorage?.setItem("retry-lazy-refreshed", "true");
          return window?.location?.reload();
        }
        reject(error);
      });
  });
};

const HomePage = lazy(() =>
  lazyRetry(() => import("./pages/homepage/HomePage"))
);
const AboutUs = lazy(() =>
  lazyRetry(() => import("../src/pages/homepage/AboutUs/AboutUs"))
);
const CreateUser = lazy(() =>
  lazyRetry(() => import("../src/Components/CreateUser/CreateUser"))
);
const SingleProductDetails = lazy(() =>
  lazyRetry(() => import("./pages/SingleProductDetail/SingleProductDetails"))
);
const LoginPage = lazy(() =>
  lazyRetry(() => import("../src/pages/Login/LoginPage"))
);
const Cart = lazy(() => lazyRetry(() => import("../src/pages/Cart/Cart")));
const ForgotPassword = lazy(() =>
  lazyRetry(() => import("./pages/ForgotPassword"))
);
const ResendEmail = lazy(() => lazyRetry(() => import("./pages/ResendEmail")));
const NewPassword = lazy(() => lazyRetry(() => import("./pages/NewPassword")));
const ContinuePage = lazy(() =>
  lazyRetry(() => import("./pages/ContinuePage"))
);
const AddCard = lazy(() => lazyRetry(() => import("./pages/AddCard/AddCard")));
const ScrollTop = lazy(() => lazyRetry(() => import("./Components/ScrollTop")));
const MyPurchase = lazy(() =>
  lazyRetry(() => import("./pages/MyPurchase/MyPurchase"))
);
const AllProducts = lazy(() =>
  lazyRetry(() => import("../src/pages/AllProducts/AllProducts"))
);
const SocketConnection = lazy(() =>
  lazyRetry(() => import("./Components/SocketConnection"))
);
const Jumio = lazy(() => lazyRetry(() => import("./Components/Jumio")));
const VerifyIndentity = lazy(() =>
  lazyRetry(() => import("./pages/VerifyIdentity/VerifyIndentity"))
);
const Contact = lazy(() => lazyRetry(() => import("./pages/Contact/Contact")));
const AffiliateArtist = lazy(() =>
  lazyRetry(() => import("./pages/AffiliateArtist/AffiliateArtist.js"))
);
const SuccessScreenDigitalProduct = lazy(() =>
  lazyRetry(() => import("./pages/PurchasingSuccess/SuccessScreenGiftCard"))
);
const PrivacyPolicy = lazy(() =>
  lazyRetry(() => import("./pages/Policy/PrivacyPolicy"))
);
const TermsofUse = lazy(() =>
  lazyRetry(() => import("./pages/Policy/TermOfUse"))
);
const DataDeletion = lazy(() =>
  lazyRetry(() => import("./pages/Policy/DataDeletion"))
);
const KycInfo = lazy(() => lazyRetry(() => import("./pages/kycInfo/KycInfo")));

const RouteLoader = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, [location]);

  return isLoading ? <Loader /> : children;
};

// logo-rocket
LogRocket.init("ddft1a/jkdigitals");
function App() {
  // console.log('console in app log rocket', LogRocket.init('ddft1a/jkdigitals'));
  return (
    <Provider store={store}>
      <SocketConnection />
      <Suspense fallback={<Loader />}>
        <Router>
          <CoinflowPurchaseProtection
            merchantId={merchantId}
            coinflowEnv={coinflowEnv}
          />
          <RouteLoader>
            <ScrollTop />

            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/about-us" element={<AboutUs />} />
              <Route
                exact
                path="/product-details/:id"
                element={
                  <ProtectedRoutes>
                    <SingleProductDetails />
                  </ProtectedRoutes>
                }
              />{" "}
              {/* <Route
            exact
            path="/gift-card-details/:id"
            element={
              <ProtectedRoutes>
                <SingleProductDetails />
              </ProtectedRoutes>
            }
          /> */}
              {/* <Route
            exact
            path="/payment-success"
            element={
              <ProtectedRoutes>
                <SuccessScreeen />
              </ProtectedRoutes>
            }
          /> */}
              <Route
                exact
                path="/payment-success"
                element={
                  <ProtectedRoutes>
                    <SuccessScreenDigitalProduct />
                  </ProtectedRoutes>
                }
              />
              <Route
                exact
                path="/my-purchases"
                element={
                  <ProtectedRoutes>
                    <MyPurchase />
                  </ProtectedRoutes>
                }
              />
              <Route
                exact
                path="/verify"
                element={
                  <ProtectedRoutes>
                    <VerifyIndentity />
                  </ProtectedRoutes>
                }
              />
              <Route exact path="/products" element={<AllProducts />} />
              {/* <Route
            exact
            path="/gift-cards"
            element={
              <ProtectedRoutes>
                <GiftCards />
              </ProtectedRoutes>
            }
          /> */}
              <Route
                exact
                path="/signup"
                element={
                  <Redirect>
                    <CreateUser />
                  </Redirect>
                }
              />
              <Route
                exact
                path="/login"
                element={
                  <Redirect>
                    <LoginPage />
                  </Redirect>
                }
              />
              <Route
                exact
                path="/cart"
                element={
                  <ProtectedRoutes>
                    <Cart />
                  </ProtectedRoutes>
                }
              />
              <Route
                exact
                path="/forgot-password"
                element={
                  <Redirect>
                    <ForgotPassword />
                  </Redirect>
                }
              />
              <Route
                exact
                path="/resend-email"
                element={
                  <Redirect>
                    <ResendEmail />
                  </Redirect>
                }
              />
              <Route
                exact
                path="/new-password/:id"
                element={
                  <Redirect>
                    <NewPassword />
                  </Redirect>
                }
              />
              <Route
                exact
                path="/continue-password"
                element={
                  <Redirect>
                    <ContinuePage />
                  </Redirect>
                }
              />
              <Route exact path="/contact" element={<Contact />} />
              <Route
                exact
                path="/affiliate-artist"
                element={<AffiliateArtist />}
              />
              <Route
                exact
                path="/add-card/:orderId?"
                element={
                  <ProtectedRoutes>
                    <AddCard />
                  </ProtectedRoutes>
                }
              />
              <Route
                exact
                path="/kyc-jumio"
                element={
                  <ProtectedRoutes>
                    <Jumio />
                  </ProtectedRoutes>
                }
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/kyc-info" element={<KycInfo />} />
              <Route path="/data-deletion" element={<DataDeletion />} />
              <Route path="/use-policy-terms" element={<TermsofUse />} />
            </Routes>
          </RouteLoader>
        </Router>
      </Suspense>
    </Provider>
  );
}

export default App;
